// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Ubuntu/Ubuntu-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Ubuntu/Ubuntu-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Lato/Lato-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/Lato/Lato-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n\tfont-family: 'Ubuntu';\n\tsrc: local('Ubuntu'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n\tfont-weight: 400;\n}\n\n@font-face {\n\tfont-family: 'Ubuntu';\n\tsrc: local('Ubuntu'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('truetype');\n\tfont-weight: 700;\n}\n\n@font-face {\n\tfont-family: 'Lato';\n\tsrc: local('Lato'), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n\tfont-weight: 400;\n}\n\n@font-face {\n\tfont-family: 'Lato';\n\tsrc: local('Lato'), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('truetype');\n\tfont-weight: 700;\n}\n\n/* Primarily this is the Ubuntu font provider */\n.App {\n\tfont-family: 'Ubuntu', sans-serif;\n\toverflow-x: hidden;\n}\n", "",{"version":3,"sources":["webpack://./src/assets/css/App.css"],"names":[],"mappings":"AAAA;CACC,qBAAqB;CACrB,gFAAkF;CAClF,gBAAgB;AACjB;;AAEA;CACC,qBAAqB;CACrB,gFAA+E;CAC/E,gBAAgB;AACjB;;AAEA;CACC,mBAAmB;CACnB,8EAA4E;CAC5E,gBAAgB;AACjB;;AAEA;CACC,mBAAmB;CACnB,8EAAyE;CACzE,gBAAgB;AACjB;;AAEA,+CAA+C;AAC/C;CACC,iCAAiC;CACjC,kBAAkB;AACnB","sourcesContent":["@font-face {\n\tfont-family: 'Ubuntu';\n\tsrc: local('Ubuntu'), url('../fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype');\n\tfont-weight: 400;\n}\n\n@font-face {\n\tfont-family: 'Ubuntu';\n\tsrc: local('Ubuntu'), url('../fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype');\n\tfont-weight: 700;\n}\n\n@font-face {\n\tfont-family: 'Lato';\n\tsrc: local('Lato'), url('../fonts/Lato/Lato-Regular.ttf') format('truetype');\n\tfont-weight: 400;\n}\n\n@font-face {\n\tfont-family: 'Lato';\n\tsrc: local('Lato'), url('../fonts/Lato/Lato-Bold.ttf') format('truetype');\n\tfont-weight: 700;\n}\n\n/* Primarily this is the Ubuntu font provider */\n.App {\n\tfont-family: 'Ubuntu', sans-serif;\n\toverflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
