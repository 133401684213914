import { createTheme } from '@mui/material/styles';

const PRIMARY_ORANGE = '#FA7007';
const SECONDARY_ORANGE = '#FCEDE9';
const GRAY = '#5E7181';
const GRAY_DISABLED = '#92A4AD';
const DEFAULT_WHITE = '#FFF';
const DEFAULT_BLACK = '#000000';
const GREEN = '#0C773A';
const SECONDARY_GREEN = '#E6FEDB';

// A custom theme for this app
const theme = createTheme({
	typography: {
		fontFamily: 'Ubuntu',
	},
	palette: {
		background: {
			default: DEFAULT_WHITE,
			primary: PRIMARY_ORANGE,
			secondary: SECONDARY_ORANGE,
			success: SECONDARY_GREEN,
		},
		typography: {
			default: DEFAULT_BLACK,
			primary: PRIMARY_ORANGE,
			white: DEFAULT_WHITE,
			gray: GRAY,
			success: GREEN,
			disabled: GRAY_DISABLED,
		},
		color: {
			primary: PRIMARY_ORANGE,
		},
	},
	components: {
		MuiSelect: {
			styleOverrides: {
				outlined: { borderColor: 'green' },
				standard: { backgroundColor: DEFAULT_WHITE, borderRadius: '6px' },
				root: {
					backgroundColor: DEFAULT_WHITE,
					borderRadius: '6px',
					height: '56px',
					paddingX: '24px',
					borderColor: 'green',
				},
				iconStandard: {
					color: GRAY,
				},
				select: { borderColor: 'green' },
			},
		},
	},
});

export default theme;
