import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { openInNewTab } from '../../features/navigation/utils';
import Typography from '@mui/material/Typography';
import { useLocalizedDownloadAppOptions } from '../../features/localization/hooks';
import { SOCIAL_MEDIA_MENU_ITEMS } from './constants';

const buttonStyle = { marginX: '8px', padding: 0, minWidth: 0 };
const iconStyle = { height: '20px' };

const currentYear = new Date().getFullYear();

const FooterLinks = () => {
	const downloadAppOptions = useLocalizedDownloadAppOptions();
	return (
		<Box sx={{ paddingTop: '32px', padding: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<Box sx={{ paddingTop: '24px', paddingX: '16px', alignItems: 'center' }}>
				{downloadAppOptions.map(({ code, link, icon, heightRatio }) => {
					const height = Math.round(30 * heightRatio);
					return (
						<Button onClick={openInNewTab(link)} key={code} sx={{ ...buttonStyle, height }}>
							<img style={{ height }} src={icon} alt={code} />
						</Button>
					);
				})}
			</Box>
			<Box sx={{ paddingTop: '16px', paddingX: 0, alignItems: 'center' }}>
				{SOCIAL_MEDIA_MENU_ITEMS.map(({ code, link, icon }) => (
					<Button onClick={openInNewTab(link)} key={code} sx={buttonStyle}>
						<img style={iconStyle} src={icon} alt={code} />
					</Button>
				))}
			</Box>
			<Box sx={{ paddingTop: '24px', paddingBottom: '8px', paddingX: 0, alignItems: 'center' }}>
				<Typography
					sx={{
						color: 'typography.white',
						fontSize: '12px',
						fontWeight: 400,
						lineHeight: '18px',
						letterSpacing: '0em',
						textAlign: 'center',
					}}>
					{`© OLOOPO s.r.o. ${currentYear}. Všechna práva vyhrazena.`}
				</Typography>
			</Box>
		</Box>
	);
};

export default FooterLinks;
