import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from '../../../features/localization/hooks';
import { TextArea, TitlePrimary } from '../../../Layout/Page';
import { BULLETS } from '../constant';
import careersClaim from '../../../assets/images/careersClaim.svg';
import careersGraphic from '../../../assets/images/careersGraphic.svg';

const CareersIntroSectionMD = () => {
	const t = useTranslation('careers');
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				background: '#FFF',
				paddingTop: '24px',
				alignSelf: 'center',
				alignContent: 'center',
			}}>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						width: '50%',
						background: '#FFF',
					}}>
					<TitlePrimary text={t('introSectionHeader')} />
					<div style={{ paddingTop: '30px' }}>
						<Typography
							sx={{
								fontSize: '22px',
								fontWeight: 400,
								lineHeight: '33px',
								letterSpacing: '0em',
								textAlign: 'left',
								maxWidth: '534px',
							}}>
							{t('introSectionFirstText')}
						</Typography>
					</div>
					<div style={{ paddingTop: '20px' }}>
						<Typography
							sx={{
								fontSize: '22px',
								fontWeight: 400,
								lineHeight: '33px',
								letterSpacing: '0em',
								textAlign: 'left',
								maxWidth: '534px',
							}}>
							{t('introSectionSecondText')}
						</Typography>
					</div>
				</div>
				<div
					style={{
						width: '700px',
						background: '#FFF',
					}}>
					<img
						src={careersClaim}
						alt={'careersClaim'}
						style={{ width: '270%', position: 'relative', left: '-40%', top: '-25%' }}
					/>
				</div>
			</div>
			<Box sx={{ alignSelf: 'center', marginTop: { xs: '32px', md: '-35%' } }}>
				{BULLETS.map(({ key, img }) => (
					<Box
						key={key}
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center',
							height: '60px',
							paddingLeft: { xs: '8px', md: '40px' },
						}}>
						<Box
							sx={{
								width: { xs: '50px', md: '66px' },
								height: { xs: '44px', md: '52px' },
								minWidth: { xs: '50px', md: '66px' },
								marginRight: { xs: '24px', md: '32px' },
							}}>
							<img src={img} alt="careers graphic" width="100%" height="100%" />
						</Box>
						<TextArea text={t(key)} topDivider="0px" bold />
					</Box>
				))}
			</Box>
			<Box sx={{ display: 'flex', alignSelf: 'center', marginX: { xs: '0px' }, marginTop: { md: '30px' } }}>
				<img src={careersGraphic} alt="careersGraphic" style={{ marginTop: '40px' }} />
			</Box>
		</div>
	);
};

export default CareersIntroSectionMD;
