import { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLessSharp';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import { useTranslation } from '../../../features/localization/hooks';

const textSx = {
	textTransform: 'capitalize',
	fontSize: '16px',
	fontWeight: 400,
	letterSpacing: '0em',
	textAlign: 'left',
	'&.Mui-focused': { color: 'typography.gray' },
};

const SelectForm = ({ options, name, onChange, formData, section }) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const t = useTranslation('careers');
	const label = t(`form.${section}.${name}`);

	return (
		<FormControl fullWidth>
			<InputLabel
				sx={{
					...textSx,
					marginTop: '16px',
				}}
				variant={'filled'}>
				{label}
			</InputLabel>
			<Select
				onOpen={() => setIsExpanded(true)}
				onClose={() => setIsExpanded(false)}
				inputProps={{
					sx: textSx,
				}}
				sx={{ paddingX: '12px', paddingTop: '16px' }}
				endAdornment={
					<InputAdornment position="end" sx={{ marginRight: '8px', marginTop: '-14px', color: 'typography.gray' }}>
						{isExpanded ? (
							<ExpandLessIcon sx={{ color: 'typography.disabled' }} />
						) : (
							<ExpandMoreIcon sx={{ color: 'typography.disabled' }} />
						)}
					</InputAdornment>
				}
				IconComponent={null}
				disableUnderline
				variant={'standard'}
				name={name}
				value={formData[name]}
				label={label}
				onChange={onChange}>
				{options.map(({ id, label, value }) => (
					<MenuItem
						sx={{
							color: 'typography.gray',
							'&.Mui-selected': {
								color: 'typography.primary',
								fontWeight: 700,
								fontSize: '17px',
								backgroundColor: 'white',
							},
						}}
						key={id}
						value={value}>
						{label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

SelectForm.propTypes = {
	options: PropTypes.array,
	section: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
	formData: PropTypes.object,
};

export default SelectForm;
