import Typography from '@mui/material/Typography';

const DEFAULT_TOP_DIVIDER = { xs: '16px', md: '32px' };
const DEFAULT_BOTTOM_DIVIDER = '0px';
const DEFAULT_LINE_HEIGHT_MD = '24px';
const DEFAULT_FONT_WEIGHT = 400;
const BOLD_FONT_WEIGHT = 700;

const TextArea = ({
	text,
	topDivider = DEFAULT_TOP_DIVIDER,
	bottomDivider = DEFAULT_BOTTOM_DIVIDER,
	lineHeightMD = DEFAULT_LINE_HEIGHT_MD,
	bold = false,
	color = 'typography.default',
	inline = false,
}) => {
	return (
		<Typography
			paragraph={false}
			sx={{
				color,
				fontSize: { xs: '16px', md: '22px' },
				fontWeight: bold ? BOLD_FONT_WEIGHT : DEFAULT_FONT_WEIGHT,
				lineHeight: { xs: '24px', md: lineHeightMD },
				letterSpacing: '0em',
				paddingTop: topDivider,
				paddingBottom: bottomDivider,
				display: inline ? 'inline' : undefined,
			}}>
			{text}
		</Typography>
	);
};

export default TextArea;
