import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const TitledContent = ({ title, children }) => (
	<Box sx={{ paddingX: { xs: '16px', md: '16px' }, paddingY: { xs: '24px', md: '64px' } }}>
		<Typography
			sx={{
				color: 'typography.default',
				fontSize: { xs: '30px', md: '64px' },
				fontWeight: 700,
				lineHeight: { xs: '45px', md: '96px' },
				letterSpacing: '0em',
			}}>
			{title}
		</Typography>
		<Box sx={{ paddingTop: { xs: '24px', md: '40' } }}>{children}</Box>
	</Box>
);

export default TitledContent;
