import swapSx from '../../../../assets/images/swapSx.svg';
import Typography from '@mui/material/Typography';
import ButtonPrimary from '../../../../common/Components/ButtonPrimary';
import { getAgentSystem, openInNewTab } from '../../../../features/navigation/utils';
import { platforms } from '../../../../features/navigation/constants';
import Button from '@mui/material/Button';
import { useLocalizedDownloadAppOptions, useTranslation } from '../../../../features/localization/hooks';

const IntroSection = () => {
	const t = useTranslation('landingPage');
	const downloadAppOptions = useLocalizedDownloadAppOptions();
	return (
		<>
			{/* IMAGE SECTION */}
			<div style={{ overflow: 'hidden' }}>
				<img src={swapSx} alt={'swapSx'} style={{ width: '100%' }} />
			</div>
			{/* TEXT + CTA BUTTON SECTION */}
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					position: 'sticky',
					bottom: '24px',
					background: '#FFF',
				}}>
				{/* WORDS SUB-SECTION */}
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						padding: '16px 24px',
					}}>
					{/* TEXT SUB-SECTION */}
					{[t('claimDontBuy'), t('claimOloopoIt')].map((text, index) => (
						<Typography
							sx={{
								fontSize: '30px',
								fontWeight: 700,
								lineHeight: '45px',
								letterSpacing: '0em',
								textAlign: 'center',
							}}>
							{text}
						</Typography>
					))}
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
					<Typography
						sx={{
							fontSize: '16px',
							fontWeight: 400,
							lineHeight: '24px',
							letterSpacing: '0em',
							textAlign: 'center',
							maxWidth: '267px',
						}}>
						{t('claimIntro')}
					</Typography>
				</div>
				{/* TEXT SUB-SECTION */}
				<div
					style={{
						paddingTop: '24px',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						position: 'sticky',
						bottom: '24px',
					}}>
					{getAgentSystem() === platforms.UNKNOWN ? (
						downloadAppOptions.map(({ code, link, icon, heightRatio }) => {
							const height = Math.round(48 * heightRatio);
							return (
								<Button onClick={openInNewTab(link)} key={code} sx={{ marginX: '8px', padding: 0, height }}>
									<img style={{ height }} src={icon} alt={code} />
								</Button>
							);
						})
					) : (
						<ButtonPrimary label={t('downloadBtn')} onClick={openInNewTab('http://tst.oloopo.com:8080')} />
					)}
				</div>
			</div>
		</>
	);
};

export default IntroSection;
