import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import OloopoLogo from '../../common/Components/OloopoLogo/OloopoLogo';
import FootFooterMenu from './FooterMenu';
import FooterLinks from './FooterLinks';
import FooterLinksMd from './FooterLinksMd';
import '../../assets/css/Footer.css';
import { routes } from '../../features/navigation/constants';
import { useTranslation } from '../../features/localization/hooks';

const COMPANY_MENU_ITEMS = [routes.ABOUT_US, routes.CAREERS, routes.TUTORIALS];

const SUPPORT_MENU_ITEMS = [routes.PRIVACY, routes.TERMS_AND_CONDITIONS, routes.SUPPORT_AND_FAQ];

const currentYear = new Date().getFullYear();

const Footer = () => {
	const t = useTranslation('common');
	const companyMenu = <FootFooterMenu title={t('footer.company')} navItems={COMPANY_MENU_ITEMS} />;
	const supportMenu = <FootFooterMenu title={t('footer.support')} navItems={SUPPORT_MENU_ITEMS} />;
	return (
		<>
			<Box sx={{ display: { xs: 'block', md: 'none' } }}>
				<Box sx={{ paddingTop: '24px', paddingX: '16px', backgroundColor: 'background.primary' }}>
					<OloopoLogo inverted height="30px" />
					{companyMenu}
					{supportMenu}
					<FooterLinks />
				</Box>
			</Box>
			<Box sx={{ display: { xs: 'none', md: 'block' } }}>
				<Box
					sx={{
						paddingTop: '48px',
						paddingX: '16px',
						backgroundColor: 'background.primary',
					}}>
					<Container disableGutters maxWidth="lg">
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-start',
							}}>
							<FooterLinksMd />
							<div style={{ width: '300px' }}>{companyMenu}</div>
							<div style={{ width: '300px' }}>{supportMenu}</div>
						</Box>
						<Box
							sx={{
								paddingTop: '24px',
								paddingBottom: '16px',
								paddingX: 0,
								height: '100px',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
							}}>
							<Typography
								sx={{
									color: 'typography.white',
									fontSize: '14px',
									fontWeight: 400,
									lineHeight: '24px',
									letterSpacing: '0em',
									textAlign: 'left',
								}}>
								{t('footer.copyRight', { currentYear })}
							</Typography>
						</Box>
					</Container>
				</Box>
			</Box>
		</>
	);
};

export default Footer;
