import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import theme from '../../../mui/theme';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../localization/hooks';
import { PRIVACY_NAVIGATION_TO_COOKIES_PANEL_URL } from '../constants';

const CookiesDisclaimer = () => {
	const t = useTranslation('cookies');
	return (
		<Box>
			<Typography
				variant="body2"
				component="span"
				sx={{
					color: 'typography.default',
					fontSize: '14px',
					fontWeight: 400,
					lineHeight: '21px',
					letterSpacing: '0em',
				}}>
				{t('disclaimer.textPartOne')}
			</Typography>
			<Link
				to={PRIVACY_NAVIGATION_TO_COOKIES_PANEL_URL}
				style={{
					color: theme.palette.typography.primary,
					fontSize: '14px',
					fontWeight: 700,
					lineHeight: '21px',
					letterSpacing: '0em',
					cursor: 'pointer',
				}}>
				{t('disclaimer.textLink')}
			</Link>
			<Typography
				variant="body2"
				component="span"
				sx={{
					color: 'typography.default',
					fontSize: '14px',
					fontWeight: 400,
					lineHeight: '21px',
				}}>
				{t('disclaimer.textPartTwo')}
			</Typography>
		</Box>
	);
};

export default CookiesDisclaimer;
